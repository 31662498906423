<template>
  <page-container>
    <transition
      name="card"
      appear
    >
      <Form
        v-slot="{ meta }"
        slim
      >
        <base-card>
          <template v-if="submitted">
            <base-text
              class="info-form__heading"
              tag="h2"
              type="display-large"
            >
              <render-content>
                {{ $content.moduleMissingOrder.successHeading }}
              </render-content>
            </base-text>
            <base-text class="info-form__copy">
              <render-content>
                {{ $content.moduleMissingOrder.successSubheading }}
              </render-content>
            </base-text>
            <base-button
              class="info-form__back-to-shop"
              :to="shopLink"
            >
              <render-content>
                {{ $content.moduleMissingOrder.successButtonCopy }}
              </render-content>
            </base-button>
          </template>

          <template v-else>
            <div v-if="data.header === 'formHeading'">
              <h2>{{ $content.moduleGiftReturns[data.header] }}</h2>
              <p class="IF-subheader">
                {{ $content.moduleGiftReturns[data.subheader] }}
              </p>
            </div>
            <div v-else>
              <h2>{{ $content.moduleMissingOrder[data.header] }}</h2>
              <p class="IF-subheader">
                {{ $content.moduleMissingOrder[data.subheader] }}
              </p>
            </div>

            <Field
              v-for="form in data.forms"
              :key="form.key"
              v-slot="{ errors, field }"
              v-model="form.value"
              class="IF-input"
              :name="form.key"
              :rules="form.required ? 'required' : null"
              slim
            >
              <div class="IF-input">
                <base-input
                  v-bind="field"
                  :model-value="form.value"
                  :type="form.type"
                  :label="$content.moduleMissingOrder[form.label]"
                  :placeholder="form.placeholder"
                  :error="errors[0]"
                  :required="form.required"
                  :help-text="form.addendum"
                />
              </div>
            </Field>

            <base-text
              class="info-form__subheading"
              tag="h3"
              type="display-small"
            >
              {{ $content.moduleMissingOrder.contactInfoHeading }}
            </base-text>

            <div class="IF-input contact">
              <Field
                v-slot="{ errors, field }"
                v-model="inputs.email"
                name="email"
                rules="required|email"
                slim
              >
                <base-input
                  v-model="inputs.email"
                  v-bind="field"
                  type="email"
                  :label="$content.moduleMissingOrder.email"
                  :error="errors[0]"
                  required
                />
              </Field>
            </div>

            <base-text
              class="info-form__heading"
              tag="h3"
              type="display-small"
            >
              {{ $content.moduleMissingOrder.shippingAddressHeading }}
            </base-text>

            <address-form-wrapper
              v-if="data.addressInput"
              v-model="addressData"
              :required="['phone']"
            />

            <div class="IF-buttons">
              <base-button
                type="text"
                icon="chevron-left"
                @click.prevent="goBack"
              >
                <render-content>
                  {{ $content.moduleMissingOrder[data.back] }}
                </render-content>
              </base-button>
              <base-button
                :disabled="!meta.valid || !meta.dirty"
                :activated="submitting"
                data-testid="submit-form-button"
                @click.prevent="submitForm"
              >
                <render-content>
                  {{ $content.moduleMissingInfo.submit }}
                </render-content>
              </base-button>
            </div>
          </template>
        </base-card>
      </Form>
    </transition>
  </page-container>
</template>

<script>
import {
  BaseCard,
  BaseInput,
  BaseButton,
  BaseText,
} from '@loophq/design-system';
import AddressFormWrapper from '@/components/forms/AddressFormWrapper';
import PageContainer from '@/components/layout/PageContainer';
import Gift from '@/js/controllers/gift';

const formatAddress = (address) => {
  const { first_name, last_name, ...rest } = address;
  return {
    ...rest,
    firstName: first_name,
    lastName: last_name,
  };
};

export default {
  components: {
    AddressFormWrapper,
    PageContainer,
    BaseCard,
    BaseInput,
    BaseButton,
    BaseText,
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: function(value) {
        return value.header && value.forms;
      }
    }
  },
  emits: ['closeForm'],
  data() {
    return {
      addressData: null,
      inputs: {
        email: ''
      },
      submitting: false,
      submitted: false,
    };
  },

  computed: {
    order() {
      return this.$store.getters.order;
    },
    shopLink() {
      return this.$content.pageLookup.shopLink || `https://${this.$shop.shop_domain}`;
    },
  },

  mounted() {
    this.addressData = {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      countryCode: '',
      zip: '',
      phone: ''
    };

    // Only load the order address once during component mounting
    if (this.order && this.order.address) {
      const formattedAddress = formatAddress(this.order.address);

      // Only assign values that are not null or undefined
      Object.entries(formattedAddress).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          this.addressData[key] = value; // Directly assign the value to addressData
        }
      });
    }
  },

  methods: {
    goBack() {
      this.$emit('closeForm');
    },
    submitForm() {
      const formData = this.data.forms.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.key]: cur.value
        };
      }, {});
      const { firstName, lastName, countryCode, ...restOfAddress } = this.addressData;
      const payload = {
        customer_email: this.inputs.email,
        address: {
          ...restOfAddress,
          first_name: firstName,
          last_name: lastName,
          name: this.addressData.firstName + ' ' + this.addressData.lastName,
          country_code: countryCode
        },
        ...formData
      };

      this.submitting = true;

      // If we're just collecting info to attach to an order,
      // attach the data to the order and push them to start the return
      if (!this.data.forms.length) {
        this.$store.commit('updateOrder', {
          ...this.order,
          address: payload.address,
          customer: {
            ...this.order.customer,
            email: payload.customer_email
          }
        });
        this.$store.commit('return/setAddress', payload.address);
        this.$store.commit('return/setCustomer', {
          email: payload.customer_email
        });
        this.$router.push({ name: 'exchange' });
        return false;
      }

      // Otherwise, save this for the merchant to look at and stop them here
      this.save(payload);
    },
    async save(data) {
      try {
        await Gift.createRequest(data);
        this.submitted = true;
      } catch (error) {
        this.submitting = false;
        const errMessage = error.response?.data?.error || error;
        this.$store.dispatch('toast',{ message: errMessage, type: 'error' });
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.info-form {
  &__heading {
    text-align: center;
    margin-bottom: var(--spacing-300);
  }

  &__subheading {
    margin: 1rem 0;
  }

  &__copy {
    text-align: center;
    color: var(--grey-700);
  }

  &__back-to-shop {
    width: 100%;
    margin-top: var(--spacing-400);
  }
}
</style>
