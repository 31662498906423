<template>
  <div
    class="survey-number"
    @click="$emit('clickSurveyOption', score);"
  >
    <base-button
      class="survey-number__button"
      :class="{ 'selected': selected }"
      type="secondary"
    >
      {{ label }}
    </base-button>
  </div>
</template>

<script setup>
import { BaseButton } from '@loophq/design-system';
import { computed } from 'vue';

const labels = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

const props = defineProps({
  score: {
    type: Number,
    required: true,
  },
  selected: {
    type: Boolean,
    required: true,
  }
});

defineEmits(['clickSurveyOption']);

const label = computed(() => labels[props.score]);

</script>

<style lang="scss" scoped>
.survey-number {
  display: inline;
  cursor: pointer;

  & + & {
    margin-left: 16px;
  }
}

.survey-number__button {
  width: 36px;
  height: 36px;
  padding: 0 !important;
  border-radius: 4px;
  border: 2px solid #d8dbdf !important;
  background: #fff;

  &.selected {
    border: 2px solid $text-core-primarySelected !important;
  }
}

</style>
