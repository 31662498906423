<template>
  <transition
    name="fadein"
    appear
  >
    <Teleport
      v-if="show"
      :key="123"
      to="#app-target"
    >
      <div
        :key="id"
        ref="modal"
        class="modal-dialog"
        role="dialog"
        :aria-labelledby="`dialog-title-${id}`"
      >
        <div
          class="modal-dialog__backdrop"
          aria-hidden="true"
          @click="close"
        ></div>
        <transition
          name="toast"
          mode="in-out"
          appear
        >
          <Form
            v-slot="v"
            class="modal-dialog__container"
          >
            <section
              class="modal-dialog__content"
              data-scroll-lock-scrollable
            >
              <header
                v-if="$slots.header || heading"
                class="modal-dialog__header"
              >
                <slot name="header">
                  <base-text
                    class="modal-dialog__heading"
                    tag="h2"
                    type="display-medium"
                  >
                    <render-content>
                      {{ heading }}
                    </render-content>
                  </base-text>
                </slot>
              </header>
              <slot v-bind="v"></slot>
            </section>
            <footer
              v-if="$slots.footer"
              class="modal-dialog__footer"
            >
              <slot
                name="footer"
                v-bind="v"
              ></slot>
            </footer>
            <base-button
              class="modal-dialog__close"
              type="icon"
              icon="close"
              @click.prevent="close"
            >
              <visually-hidden>
                Close modal
              </visually-hidden>
            </base-button>
          </Form>
        </transition>
      </div>
    </Teleport>
  </transition>
</template>

<script>
/** DEPRECATED. Please use the modal from the design system. */
import {
  BaseText,
  BaseButton,
  VisuallyHidden,
} from '@loophq/design-system';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ModalDialog',
  components: {
    BaseText,
    BaseButton,
    VisuallyHidden,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    heading: {
      type: String,
      required: false,
      default: ''
    },
  },
  emits: [
    'close',
    'action',
  ],
  computed: {
    id() {
      return `modal-${uuidv4()}`;
    },
    firstFocusableEl() {
      return this.focusableEls.length > 0 ? this.focusableEls[0] : null;
    },
    lastFocusableEl() {
      return this.focusableEls.length > 0 ? this.focusableEls[this.focusableEls.length - 1] : null;
    }
  },
  watch: {
    show(value) {
      // Handle setup and teardown. Since the v-if is inside this
      // component, lifecycle hooks aren't getting called like you would
      // expect. To solve this we would need to put the v-if outside this
      // modal, which seems clunky
      this.$nextTick(() => {
        if (value) {
          this.handleOpen();
        } else {
          this.handleClose();
        }
      });
    }
  },
  mounted() {
    // We need to run this on initial mount for the initial
    // render, otherwise there's a case where handleOpen never gets
    // called because show never changes
    if (this.show) {
      this.handleOpen();
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    triggerAction(name) {
      this.$emit('action', { name });
    },
    handleKeydown(e) {
      const handleBackwardTab = () => {
        if (document.activeElement === this.firstFocusableEl) {
          e.preventDefault();
          this.lastFocusableEl.focus();
        }
      };

      const handleForwardTab = () => {
        if (document.activeElement === this.lastFocusableEl) {
          e.preventDefault();
          this.firstFocusableEl.focus();
        }
      };

      switch (e.key) {
        case 'Tab':
          if (this.focusableEls.length === 1) {
            e.preventDefault();
            break;
          }
          if (e.shiftKey) {
            handleBackwardTab();
          } else {
            handleForwardTab();
          }
          break;
        case 'Escape':
          this.close();
          break;
        default:
          break;
      }
    },
    handleOpen() {
      disablePageScroll();
      this.$nextTick(() => {
        // Selectors to be used for getting all elements that are focusable
        const selectors = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

        // Cache previously focused element
        this.focusedBeforeOpen = document.activeElement;

        // Get a list of all focusable elements in this modal
        if (this.$refs.modal) {
          this.focusableEls = [...this.$refs.modal.querySelectorAll(selectors)];

          if (this.focusableEls.length) {
            // Focus the first focusable element
            this.focusableEls[0].focus();

            // Add event listener for tab/shift + tab/escape
            this.$refs.modal.addEventListener('keydown', this.handleKeydown);
          }
        }
      });
    },
    handleClose() {
      // Reset data back to default
      this.focusedBeforeOpen = null;
      this.focusableEls = [];

      // Clean up listeners so we don't have tons of orphaned
      // event listeners taking up all of the ram
      if (this.$refs.modal) {
        this.$refs.modal.removeEventListener('keydown', this.handleKeydown);
      }

      // Switch focus back to previously focused element
      if (this.focusedBeforeOpen) {
        this.focusedBeforeOpen.focus();
      }

      enablePageScroll();
    }
  }
};
</script>


<style lang="scss" scoped>
$block: '.modal-dialog';

#{$block} {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100001;
  backface-visibility: hidden;

  .toast-enter-active,
  .toast-leave-active {
    transition: opacity var(--transition-300), transform var(--transition-300);
  }

  .toast-enter,
  .toast-leave-to {
    opacity: 0;
    transform: translate3d(0, 6px, 0);
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-800);
    opacity: 0.5;
  }

  &__container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 600px;
    max-height: 75vh;
    padding: var(--spacing-600);
    background-color: white;
    border-radius: calc(var(--corners) * 2);
    box-shadow: 0 1px 4px rgba(black, 0.12), 0 6px 12px rgba(black, 0.08);
  }

  &__content {
    position: relative;
    z-index: 0;
    width: calc(100% + (var(--spacing-600) * 2));
    left: calc(var(--spacing-600) * -1);
    padding: var(--spacing-600);
    padding-top: var(--spacing-300);
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
    margin-top: var(--spacing-300);
    -webkit-overflow-scrolling: touch;
  }

  &__header {
    flex-shrink: 0;
    margin-bottom: var(--spacing-300);
  }

  &__footer {
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    padding-top: var(--spacing-300);
  }

  &__close {
    position: absolute;
    // We need to shift the actual button up by the difference of the
    // button width/height and the icon width/height
    top: calc(var(--spacing-600) - 1.25rem);
    right: calc(var(--spacing-600) - 1.25rem);
    color: var(--primary-color);
  }
}

@media screen and (max-width: $break-small) {
  .modal-dialog {
    &__container {
      width: calc(100% - var(--spacing-300));
      max-height: calc(100vh - var(--spacing-300));
      padding: var(--spacing-300);
    }
  }
}
</style>
