<template>
  <div class="survey-form">
    <base-text
      class="survey-form__heading"
      tag="h3"
      type="display-small"
    >
      <render-content>
        {{ $content.moduleSurvey.question }}
      </render-content>
    </base-text>

    <div class="survey-form__scores-wrap">
      <div class="survey-form__scores">
        <survey-number
          v-for="score in scores"
          :key="score"
          class="survey-form__score"
          :score="score"
          :selected="selectedScore === score"
          @click-survey-option="handleClickSurveyOption"
        />
      </div>
      <div class="survey-form__scores-labels">
        <base-text
          class="survey-form__scores-label"
          type="body-2"
        >
          {{ $content.moduleSurvey.lowest }}
        </base-text>
        <base-text
          class="survey-form__scores-label"
          type="body-2"
        >
          {{ $content.moduleSurvey.highest }}
        </base-text>
      </div>
    </div>

    <div
      class="survey-form__comment"
    >
      <base-input
        v-model="comment"
        class="survey-form__comment-input"
        type="textarea"
        :label="$content.moduleSurvey.commentHint"
      />
    </div>

    <div
      class="survey-form__submit"
    >
      <base-button
        class="survey-form__submit-button"
        type="primary"
        :disabled="submitDisabled"
        @click="$emit('surveySubmitted', { selectedScore, comment })"
      >
        {{ $content.moduleSurvey.submitLabel }}
      </base-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import {
  BaseButton,
  BaseInput,
  BaseText
} from '@loophq/design-system';
import SurveyNumber from '@/views/StatusPage/CustomerSurvey/SurveyNumber';

defineEmits(['surveySubmitted']);

const scores = ref([1, 2, 3, 4, 5]);
const selectedScore = ref(0);
const comment = ref('');

const handleClickSurveyOption = (newScore) => {
  selectedScore.value = newScore;
};

const submitDisabled = computed(() => selectedScore.value === 0);

</script>

<style lang="scss" scoped>
.survey-form__heading {
  color: $gray-darker;
  text-align: center;
  margin-bottom: 20px;
}

.survey-form__scores-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-form__scores {
  display: flex;
  justify-content: center;
}

.survey-form__scores-labels {
  display: flex;
  width: 244px;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-200);
}

.survey-form__scores-label {
  font-size: 12px;
}

.survey-form__comment {
  margin-top: var(--spacing-300);
  min-height: 110px;

  :deep(.input-wrapper__label) {
    font-size: 14px;
  }

  :deep(.base-input__input) {
    min-height: 110px;
    padding: var(--spacing-200);
  }
}

.survey-form__submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.survey-form__submit-button {
  background: var(--primary-text-color);
  border: 1px solid var(--grey-200);
}

</style>
