<template>
  <base-card class="customer-survey">
    <survey-form
      v-if="!surveyReceived"
      class="custom-survey__form"
      :return-key="returnKey"
      @survey-submitted="handleSubmitSurvey"
    />

    <base-text
      v-else
      class="customer-survey__thank-message"
      tag="h3"
      type="display-small"
    >
      <render-content>
        {{ $content.moduleSurvey.thanks }}
      </render-content>
    </base-text>
  </base-card>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { BaseCard } from '@loophq/design-system';
import SurveyForm from '@/views/StatusPage/CustomerSurvey/SurveyForm';
import { track } from '@/js/segment';
import { customerPortalEvents, products } from '@/js/constants/segment';

const store = useStore();

const props = defineProps({
  returnKey: {
    type: String,
    required: true,
  },
});

const surveyReceived = ref(false);

const handleSubmitSurvey = (e) => {
  surveyReceived.value = true;

  try {
    track(customerPortalEvents.CSAT_SUBMITTED, {
      shop: store.getters.shop.id,
      shopName: store.getters.shop.name,
      product: products.CUSTOMER_PORTAL,
      returnKey: props.returnKey,
      score: e.selectedScore,
      comment: e.comment,
    });
  } catch (e) {
    console.error(e);
  }
};
</script>

<style lang="scss" scoped>
.customer-survey {
  padding: var(--spacing-400);
  box-shadow: none;
  background: var(--grey-100);
}

.customer-survey__heading {
  color: $gray-darker;
  text-align: center;
}

@media screen and (max-width: $break-small) {
  .customer-survey {
    margin: var(--spacing-300);
    padding: var(--spacing-300) !important;
    border-radius: var(--spacing-200) !important;
    border: none !important;
    background: #f7f8f8;
  }
}
</style>
