<template>
  <main class="app-container">
    <div class="app-wrap">
      <div
        class="app-wrap__backdrop"
        :class="{
          'app-wrap__backdrop-gradient': $theme.background.image
        }"
      ></div>
      <app-header
        v-if="showAppHeader"
        :cart="cart"
        data-testid="app-header"
        @toggleCart="toggleCart"
      />
      <Teleport
        v-else-if="cart?.length > 0 && isExperimentBEligible"
        to="#header-bar-actions"
      >
        <cart-button
          :toggle-cart="toggleCart"
          :cart-length="cart.length"
        />
      </Teleport>
      <loop-sidecart
        v-bind="theme"
        :active="showCart"
        :products="cart"
        :total="totals?.credit?.available"
        :loading="loading"
        :view="returnsList"
        @toggle="toggleCart"
        @update="updateProducts"
        @next="toReview"
        @back="toggleCart"
        @to-shop="toShop"
      />
      <div
        v-if="showMultiCurrencyBanner"
        class="multicurrency-section exchange-container view"
      >
        <multicurrency-banner :order-currency="order.original_presentment_currency" />
      </div>

      <div class="app-banner exchange-banner">
        <h1 class="exchange-banner__heading">
          <render-content>
            {{ $content.pageOrder.heading }}
          </render-content>
        </h1>
        <base-text
          v-if="$settings.singleItem"
          tag="h3"
          type="display-small"
          class="exchange-banner__single-item"
        >
          <render-content>
            {{ $content.pageOrder.singleItem }}
          </render-content>
        </base-text>
        <base-text
          v-if="!$settings.singleItem"
          tag="h3"
          type="display-small"
          class="exchange-banner__add-more-later"
        >
          <render-content>
            {{ $content.pageOrder.addMoreLater }}
          </render-content>
        </base-text>
      </div>

      <div class="exchange-container view">
        <p
          v-if="eligibleItems.length && order.eligible !== 'No expiration'"
          class="exchange-container__returnable-until"
        >
          <render-content>
            {{ pageSubheading }}
          </render-content>
        </p>

        <return-coverage-card v-if="hasReturnCoverage" />

        <!-- start all loaded line items ////////////////////////////////////////////////////////-->
        <product-card-wrapper
          v-for="product in eligibleItems"
          :key="product.id"
          :loading-item="loadingItem"
          :disabled="product.disabled"
          :product="product"
          :bundle-items="bundleItems[product.id]"
          @selectProduct="selectLineItem"
          @discardTransaction="discardTransaction"
        />

        <base-tooltip
          v-if="$content.pageOrder.ineligibleItemHeadingTooltip && ineligibleLineItems.length > 0"
          class="exchange-ineligible"
        >
          <template
            #content
          >
            <render-content>
              {{ $content.pageOrder.ineligibleItemHeadingTooltip }}
            </render-content>
          </template>
          <div class="exchange-ineligible__container">
            <h2
              class="exchange-ineligible__title"
            >
              <render-content>
                {{ $content.pageOrder.ineligibleItemHeading }}
              </render-content>
            </h2>
            <base-icon
              class="info exchange-ineligible__icon"
              name="info"
            />
          </div>
        </base-tooltip>

        <h2
          v-else-if="ineligibleLineItems.length > 0"
          class="exchange-ineligible__title exchange-ineligible__title-only"
        >
          <render-content>
            {{ $content.pageOrder.ineligibleItemHeading }}
          </render-content>
        </h2>

        <!-- Return window closed -->
        <p
          v-if="!order.return_window_active && order.eligible !== 'No expiration'"
          class="exchange-ineligible__subheading"
        >
          <render-content>
            {{ $content.pageOrder.pastReturnWindow }}
          </render-content>
        </p>

        <!-- Ineligible Items -->
        <product-card-wrapper
          v-for="product in ineligibleLineItems"
          :key="product.id"
          :disabled="true"
          :product="product"
          :loading-item="loadingItem"
          :bundle-items="bundleItems[product.id]"
        >
          <template #product-additional="additionalProps">
            <resell-wrapper
              :product="product"
              :additional-props="additionalProps"
            />
          </template>
        </product-card-wrapper>
      </div>

      <!-- Bottom bar shown when item is selected -->
      <div
        v-if="selectedItems.count > 0 && !flowBFinalStep"
        class="exchange-footer"
      >
        <div class="exchange-footer__container">
          <!-- Line below has an invis span to fix spacing error -->
          <p class="exchange-footer__copy">
            {{ selectedItems.count }} {{ $content.pageOrder.countLabel }}
          </p>
          <button
            class="exchange-footer__button loader legacy-button"
            :class="{ activated: nextStepActive == true }"
            :disabled="!allItemsLoaded"
            @click="nextStep"
          >
            <render-content>
              {{ $content.pageOrder.continueButton }}
            </render-content>
          </button>
        </div>
      </div>

      <app-footer :page="exchangePage" />

      <Teleport
        v-if="candidate"
        to="#app-target"
      >
        <product-modal
          :order="order"
          :candidate="candidate"
          :status="modal"
          :image="modalImage"
          :images="modalImages"
          :module="module"
          :flowBFinalStep="flowBFinalStep"
          @close="toggleModal"
          @changeImage="changeModalImage"
        >
          <slide-module
            :image="modalImage"
            :images="modalImages"
            :candidate="candidate"
            :order="order"
            :can-exchange="!actionValidation('exchange')"
            :can-return="!actionValidation('return')"
            :can-replace="replaceValidation()"
            :eligible-items="eligibleItems"
            :selected-items="selectedItems"
            @close="toggleModal"
            @confirmReturn="confirmReturn"
            @changeImage="changeModalImage"
            @changeModule="changeModule"
            @toggleFlowBFinalStep="toggleFlowBFinalStep"
          />
          <template
            v-if="confirmed"
            #overlay
          >
            <pacer-animation
              :product="candidate"
              :outcome="confirmed"
              @finished="toggleModal"
            />
          </template>
        </product-modal>
      </Teleport>
    </div>
  </main>
</template>
<script>
import { forEach, cloneDeep } from 'lodash';
import changeCase from 'change-object-case';
import ProductCardWrapper from '@/components/product/ProductCardWrapper';
import ResellWrapper from '@/components/resell/ResellWrapper';
import MulticurrencyBanner from './Exchange/MulticurrencyBanner';
import PacerAnimation from './Exchange/PacerAnimation';
import useUtilMethods from './../js/mixins/util';
const { miniImage, loadImage } = useUtilMethods();
import { BaseIcon, BaseText, BaseTooltip } from '@loophq/design-system';
import Card from '@/components/layout/Card';
import ReturnCoverageCard from '@/components/cards/ReturnCoverage/Card';
import { experiments } from '@/js/constants/experiments';
import LoopSidecart from '@/views/Shop/Sidecart/LoopSidecart';
import CartButton from '@/views/Shop/Sidecart/CartButton';
import defaults from '@/views/Shop/Sidecart/defaults';
import { views } from '@/js/constants/views';
import { navigateToOnstore } from '@/js/onstore';
import Cart from '@/js/controllers/cart';
import Onstore from '@/js/controllers/onstore';
import { track } from '@/js/segment';
import { customerPortalEvents, customerPortalPageNames } from '@/js/constants/segment';
import useLineItems from '@/js/composables/views/ExchangePage/lineItems';
import { initPoshmarkSDK } from '@/js/utility/resell/poshmark';
import ProductModal from '@/components/globals/ProductModal';
import SlideModule from '@/components/globals/SlideModule';

changeCase.options = { recursive: true, arrayRecursive: true };

export default {
  name: 'ExchangePage',
  components: {
    BaseTooltip,
    BaseIcon,
    BaseText,
    Card,
    CartButton,
    LoopSidecart,
    PacerAnimation,
    ProductCardWrapper,
    ProductModal,
    MulticurrencyBanner,
    ResellWrapper,
    ReturnCoverageCard,
    SlideModule,
  },
  data() {
    return {
      loadingItem: '',
      nextStepActive: false,
      selectedBundle: null,
      candidate: null,
      modal: false,
      modalImage: null,
      modalImages: [],
      module: null,
      confirmed: null,
      submitting: false,
      loading: false,
      showCart: false,
      returnsList: views.RETURNS_LIST,
      flowBFinalStep: false,
      exchangePage: customerPortalPageNames.EXCHANGE_PAGE,

      // Composable assigned data properties
      bundleItems: null,
      eligibleItems: null,
      ineligibleLineItems: null,
      resellLineItems: null,
      lineItems: null,
      nonBundleLineItems: null,
      order: null,
      selectedItems: null,
      warrantyAllowedOutcomes: null,
      isReturnCoverageProduct: null,
      loadLineItem: null,
      loadVariants: null,
      validationCheck: null,
      availableDestination: null,
    };
  },
  computed: {
    showMultiCurrencyBanner() {
      return !this.$store.getters['currencies/useOrderCurrencyAsPresentment'] && this.$store.getters['currencies/isMultiCurrency'];
    },
    pageSubheading() {
      return this.warrantyEligible ? this.$content.pageOrder.warrantySubheading : this.$content.pageOrder.subheading;
    },
    warrantyEligible() {
      return this.$store.getters.settings.warrantiesEnabled && !this.order.return_window_active && this.order.warranty_window_active && this.order.active_workflow_group_id;
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    shopContents: function () {
      return this.$store.state.shopContents;
    },
    cart() {
      return this.$store.state.order?.cart ?? [];
    },
    isItemInCart() {
      return this.cart?.length > 0;
    },
    allowExchangeUpsellAndRefund() {
      return (
        this.$store.getters.settings.exchangeUpsellAndRefundAllowed &&
        this.$store.getters.settings.exchangeUpsellAndRefundEnabled
      );
    },
    isMobile() {
      return this.$screen.isMobile;
    },
    theme() {
      const theme = this.$theme;

      return {
        theme: {
          ...defaults.theme,
          cornerRadius: theme.globals.sharpness === 'round' ? '3px' : '0',
          primaryColor: theme.branding.primaryColor || null,
          headerFont: theme.heading.font || null,
          copyFont: theme.body.font || null
        },
        text: defaults
      };
    },
    replaceItemOptionEnabled() {
      return this.$store.getters.settings.enableExchangeReplaceOption;
    },
    allItemsLoaded() {
      return this.lineItems.every((item) => !item.loading);
    },
    refundAmount() {
      if (this.isItemInCart) {
        return this.$store.state.totals.receipt.total;
      }
      const { refundCredit, storeCredit } = this.totals.credit.preCalculated;
      return Math.max(refundCredit, storeCredit);
    },
    hasReturnCoverage() {
      return this.$store.getters.hasReturnCoverage;
    },
    isExperimentBEligible() {
      return (this.$store.getters.experimentVariation(experiments.RETURNS_PORTAL_FLOW_B_EXPERIMENT) === experiments.FLOW_B);
    },
    showAppHeader() {
      if (this.isExperimentBEligible && this.isMobile) {
        return false;
      }
      return true;
    },
    onStoreActive() {
      return this.$store.getters.order.enabled.on_store_api === 'yes';
    },
    poshmarkConfig() {
      return this.$store.getters['settings'].portalServicesConfigs?.poshmark ?? {};
    },
    poshmarkIsActive() {
      return typeof window !== 'undefined' &&
        typeof window.PMResell !== 'undefined' &&
        !!this.poshmarkConfig.key &&
        !!this.poshmarkConfig.partner_id &&
        this.poshmarkConfig.available_countries?.includes(this.order.address.country);
    },
    analytics() {
      return this.$store.getters['analytics/getData'];
    },
  },
  mounted() {
    window?.scrollTo(0, 0);
  },
  created() {
    const {
      availableDestination,
      bundleItems,
      eligibleItems,
      ineligibleLineItems,
      resellLineItems,
      lineItems,
      nonBundleLineItems,
      order,
      selectedItems,
      warrantyAllowedOutcomes,
      isReturnCoverageProduct,
      loadLineItem,
      loadVariants,
      validationCheck,
    } = useLineItems(this.$settings);

    this.bundleItems = bundleItems;
    this.availableDestination = availableDestination;
    this.eligibleItems = eligibleItems;
    this.ineligibleLineItems = ineligibleLineItems;
    this.resellLineItems = resellLineItems;
    this.lineItems = lineItems;
    this.nonBundleLineItems = nonBundleLineItems;
    this.order = order;
    this.selectedItems = selectedItems;
    this.warrantyAllowedOutcomes = warrantyAllowedOutcomes;
    this.isReturnCoverageProduct = isReturnCoverageProduct;
    this.loadLineItem = loadLineItem;
    this.loadVariants = loadVariants;
    this.validationCheck = validationCheck;

    if (this.$store.state.edits.active) {
      return;
    }

    if (!this.order) {
      this.$router.push('/');
    }

    // Load any line items that are not already loaded
    this.loadLineItems(this.lineItems.filter((item) => item.loading)).then(() => {
      const returned = Object.values(this.order.line_items)?.filter(
        (item) => item?.allowed?.returned
      );

      // Fire an event if this order already has a return
      if (returned.length) {
        this.$trackEvent('subsequent order lookup');
      }

      // Fire an event if all line items are returned
      if (this.eligibleItems?.length === 0) {
        this.$trackEvent('completely returned order looked up');
      }
    }).then(() => {
      if (this.poshmarkIsActive) {
        initPoshmarkSDK(this.resellLineItems, this.poshmarkConfig);
      }

      track(customerPortalEvents.ELIGIBLE_ITEM_COUNT, {
        ...this.analytics,
        eligibleItemCount: this.eligibleItems?.length,
      });
    });

    // If some line items are already loaded, make sure we have variant data as well
    if (this.order.line_items) {
      Object.values(this.order.line_items)
        .filter((item) => !item.variants)
        .forEach((item) => {
          this.$store.commit('setOriginalLineItems', {
            ...(this.order.line_items ?? {}),
            [item.id]: cloneDeep(item),
          });
          this.loadVariants(item.product_id, item.id);
        });
    }

    // Preload main image for each line item
    this.eligibleItems.forEach((item) => {
      loadImage({
        src: miniImage(item.image, 1200),
      });
    });

    // Log navigation to the returns portal if we're coming back from on-store
    if (this.$store.state.cartToken) {
      Onstore.trackNavigation(this.shopContents.id, 'returnsportal');
    }
  },
  methods: {
    loadLineItems(lineItems) {
      return Promise.all(lineItems.map((item) => this.loadLineItem(item.id, this.$content.global.productTitle)));
    },
    changeModalImage: function (image, images) {
      this.modalImage = image;
      this.modalImages = images;
    },

    changeModule: function (module) {
      this.module = module;
    },

    async confirmReturn(data) {
      this.submitTransaction({
        ...data,
        lineItemId: data.LID,
        newVariant: data.product,
      });

      // Pacer animation disabled for now while we test the cart in shop now
      /* if (this.hasShoppingCart) {
        this.confirmed = data;
      } */

      const lineItem = this.lineItems.find((item) => item.id === data.LID);
      let isBundleItem = !!lineItem?.bundle_oli_id;

      if (this.eligibleItems.length === 1 && !(this.eligibleItems[0].bundle?.partialReturns)) {
        this.$store.commit('updateData', {
          name: 'singleItemEligible',
          data: true,
          save: true
        });
      } else {
        this.$store.commit('updateData', {
          name: 'singleItemEligible',
          data: false,
          save: true
        });
      }

      if (this.isExperimentBEligible && data.storefront) {
        await this.toShop();
      }

      if (this.$settings.singleItem) {
        this.nextStep();
      }

      if (this.eligibleItems.length === 1 && !isBundleItem && !(this.isExperimentBEligible && data.storefront)) {
        this.nextStep();
      }
    },

    async addExchangeItemToCart({
      lineItemId,
      reason,
      comment,
      newVariant,
      exchangeType,
      outcome,
      outcomesSetByWorkflow,
      updatedItem,
      userInputs
    }) {
      this.$store.commit('return/addLineItem', {
        id: lineItemId,
        variantId: updatedItem.variant_id,
        productId: updatedItem.product_id,
        sku: updatedItem.sku,
        returnType: 'credit',
        resolution: 'return',
        reason: {
          id: reason.id,
          ...(comment && { comment }),
        },
        ...(exchangeType === 'advanced' && { advanced: true }),
        ...(outcome && { outcome }),
        outcomesSetByWorkflow,
        userInputs,
        imageUploads: updatedItem?.imageUploads,
      });
      this.$store.commit('return/setAllowExchangeStorefront', true);
      // If merchant is allowing exchange upsell/refund we will add to cart
      this.adding = true;
      const cart = this.cart
        ? [
          ...this.cart,
          { ...newVariant, exchangeType: exchangeType, lineItem: lineItemId },
        ]
        : [{ ...newVariant, exchangeType: exchangeType, lineItem: lineItemId }];

      this.$store.commit('updateCart', cart);
      this.$store.commit('return/addNewItem', {
        ...newVariant,
        exchange_type: exchangeType,
        line_item: lineItemId,
      });

      if (this.hasShoppingCart) {
        this.added = true;
        return;
      }

      try {
        const { receipt, errors } = await this.$store.dispatch('totals/get');

        if (errors) {
          throw new Error(errors);
        }

        cart.forEach((item) => {
          if (receipt.newItems[item.id].useStruckPrice) {
            item.price = receipt.newItems[item.id].price;
            item.struckPrice = receipt.newItems[item.id].struckPrice;
            item.shopNowDiscountPercentage = receipt.shopNowDiscountPercentage;
          }
        });

        this.$store.commit('updateOrder', {
          ...this.order,
          cart,
        });

        this.adding = false;
        this.$trackEvent('add to cart');
      } catch (error) {
        this.$store.commit('launchError', {
          errorType: 'death',
          errorMessage: error[0],
        });

        this.adding = false;
      }
    },

    async submitTransaction({
      lineItemId,
      returnType,
      reason,
      comment,
      newVariant,
      exchangeType,
      outcome,
      outcomesSetByWorkflow,
      storefront
    }) {
      let newOrder = this.order;
      let updatedItem = newOrder.line_items[lineItemId];

      this.allowExchangeUpsellAndRefund
        ? (updatedItem['returnType'] = 'credit')
        : (updatedItem['returnType'] = returnType);
      updatedItem['resolution'] = 'return';
      updatedItem['reason'] = { reason_id: reason.id, comment };
      updatedItem['storefront'] = storefront;

      if (returnType === 'exchange') {
        updatedItem['resolution'] = 'exchange';

        if (newVariant != undefined) {
          updatedItem['new_variant'] = newVariant;
          updatedItem['product'] = newVariant.id;
          if (exchangeType === 'advanced') {
            updatedItem['advanced'] = true;
          }
        } else {
          console.error('NEW VAR NOT ASSIGNED PROPERLY');
          return false;
        }
      }

      this.$store.commit('updateOrder', newOrder);

      const inputKeys = Object.keys(updatedItem).filter((key) =>
        key.includes('user_input')
      );
      const userInputs = inputKeys.reduce((obj, input) => {
        return {
          ...obj,
          [input]: updatedItem[input],
        };
      }, {});

      if (this.allowExchangeUpsellAndRefund && newVariant) {
        this.addExchangeItemToCart({
          lineItemId,
          returnType,
          reason,
          comment,
          newVariant,
          exchangeType,
          outcome,
          outcomesSetByWorkflow,
          updatedItem,
          userInputs,
        });
      } else {
        this.$store.commit('return/addLineItem', {
          id: lineItemId,
          variantId: updatedItem.variant_id,
          productId: updatedItem.product_id,
          sku: updatedItem.sku,
          returnType,
          resolution: returnType === 'exchange' ? 'exchange' : 'return',
          reason: {
            id: reason.id,
            ...(comment && { comment }),
          },
          // attach the variant that is being exchanged for this line item
          ...(newVariant && returnType === 'exchange' && { exchangeVariant: newVariant }),
          ...(exchangeType === 'advanced' && { advanced: true }),
          ...(outcome && { outcome }),
          outcomesSetByWorkflow,
          userInputs,
          imageUploads: updatedItem?.imageUploads,
        });
      }
    },

    replaceValidation: function () {
      if (this.warrantyAllowedOutcomes.replace) {
        return true;
      }

      if (
        !this.actionValidation('exchange') &&
        this.candidate.variants[0].available &&
        (!this.shopContents.use_shopify_inventory || this.candidate.variants[0].limit > 0)
      ) {
        return this.replaceItemOptionEnabled;
      }

      return false;
    },

    actionValidation: function (type) {
      let isDisabled = false;
      let that = this;

      if (this.candidate != undefined) {
        if (this.candidate.allowed[type] === false || this.order.enabled[type] === 'no') {
          isDisabled = true;
        }
        if (type === 'return') {
          if (this.order.enabled.gift === 'no' && this.order.enabled.refund === 'no' && !this.warrantyAllowedOutcomes.gift) {
            isDisabled = true;
          }
          if (
            this.candidate.allowed.gift === false &&
            this.candidate.allowed.refund === false
          ) {
            isDisabled = true;
          }
        }
        if (type === 'exchange') {
          //check for deleted variant data
          var variantExists = false;
          this.candidate.variants?.forEach(function (variant) {
            if (variantExists) {
              return true;
            }
            if (parseInt(variant.id) == that.candidate.variant_id) {
              variantExists = true;
            }
          });
          if (!variantExists) {
            isDisabled = true;
          }
        }
        return isDisabled;
      }
      return 'loading';
    },
    toggleModal(data) {
      this.modal = !this.modal;
      if (this.modal) {
        document.body.style.position = 'fixed';
      } else {
        document.body.style.position = 'inherit';
        if (!data?.slideModuleComplete) {
          this.resetUserInputs(this.candidate.id);
        }
        setTimeout(() => {
          this.candidate = null;
        }, 500);
      }

      // reset workflowGroupId if close modal before confirm return
      if (
        !this.modal
        && this.$store.getters.settings.warrantiesEnabled
        && !this.$store.state.return.lineItems.length
        && this.$store.state.return.workflowGroupId
      ) {
        this.$store.commit('return/setWorkflowGroupId', null);
      }

      this.module = null;
      this.confirmed = null;
    },

    // Activates when selecting a line item
    async selectLineItem(lineItem) {
      // This line item is already marked for return
      if (this.selectedItems.items[lineItem.id]) {
        return;
      }

      // Make sure variant data has loaded
      if (!lineItem.variants && lineItem.product_exists) {
        this.loadingItem = lineItem.id;

        try {
          lineItem = await this.loadVariants(lineItem.product_id, lineItem.id);
        } catch (error) {
          console.error(error);
          this.$error(this.$content.moduleLineItem.variantsError);
          return;
        }
      }

      // All checks complete, open the modal
      this.loadingItem = '';
      this.candidate = lineItem;
      this.toggleModal();
    },
    //Hitting the X on a selected line item
    discardTransaction(LID) {
      if (this.allowExchangeUpsellAndRefund) {
        //With use of cart - we connect the cart item by LID to remove correct item
        const removedItem = this.order.cart?.find((item) => item.lineItem === LID);
        const newCart = this.order.cart?.filter((item) => item.lineItem !== LID);

        if (removedItem) {
          this.$store.commit('return/removeNewItem', {
            variantId: removedItem.variant_id ?? removedItem.id,
          });

          this.$store.commit('updateOrder', {
            ...this.order,
            cart: newCart,
          });

        }
      }
      let newOrder = this.order;
      let restoredItem = this.$store.state.originalLineItems[LID];

      this.resetUserInputs(LID);

      newOrder.line_items[LID] = restoredItem;

      this.$store.commit('return/removeLineItem', { id: LID });

      let lineItems = this.$store.state.return.lineItems.length;
      if (lineItems === 0 && this.isExperimentBEligible) {
        this.$store.commit('updateOrder', {
          ...this.order,
          cart: [],
        });
      }
    },

    // reset user inputs
    // this allows the user to restart the flow and revisit the input slides
    resetUserInputs(lineItemId) {
      const lineItem = this.$store.state.order.line_items[lineItemId];
      if (lineItem) {
        const inputKeys = Object.keys(lineItem).filter((key) => key.includes('user_input'));
        inputKeys.forEach((userInput) => {
          // Remove each user_input unless warranty is the only option (then skip removing the SelectWarranty input)
          const warrantyWindowActive = this.$store.getters.settings.warrantiesEnabled && this.order.warranty_window_active;
          const isNotFirstUserInput = userInput !== inputKeys[0];
          if (this.order.return_window_active || (warrantyWindowActive && isNotFirstUserInput)) {
            this.$store.dispatch('removeLineItemProperty', {
              id: lineItemId,
              propertyToRemove: userInput,
            });
          }
        });
      }
    },

    checkSoloEnabled: function (type) {
      let enabled = this.order.enabled;
      let isSolo = true;

      forEach(enabled, function (value, key) {
        if (value === 'yes') {
          if (key != type && key != 'exchange') {
            isSolo = false;
          }
        }
      });
      return isSolo;
    },

    async nextStep() {
      let that = this;
      let updatedOrder = this.order;
      let skipCredit = false;

      if (this.isExperimentBEligible) {
        skipCredit = true;
      }

      this.nextStepActive = true;

      const shopNowForAll =
        this.order.return_policy.shop_now_for_all_enabled &&
        this.order.enabled.storefront === 'yes' && this.$settings.shopNowForAllEnabled;

      if (that.checkSoloEnabled('refund') && !shopNowForAll) {
        skipCredit = true;
        updatedOrder['creditType'] = 'refund';
        this.$store.commit('updateOrder', updatedOrder);
        this.$store.commit('return/setCreditType', 'refund');
      } else if (that.checkSoloEnabled('gift') && !shopNowForAll) {
        skipCredit = true;
        updatedOrder['creditType'] = 'gift';
        this.$store.commit('updateOrder', updatedOrder);
        this.$store.commit('return/setCreditType', 'gift');
      }

      if (this.order.enabled.storefront === 'yes') {
        const { product_type: productType } = this.lineItems.find(
          (item) => !!item.returnType
        );

        this.$store.dispatch('collections/getPreview', { productType });
      }

      try {
        const { errors } = await this.$store.dispatch('totals/get');

        if (errors) {
          this.nextStepActive = false;
          throw new Error(errors);
        }

        // Set a flag if we have disabled items, to be used later on the status page
        const hasDisabledItems = this.eligibleItems.some((item) => {
          return item.disabled && !item.returnType;
        });

        const newOrder = {
          ...updatedOrder,
          hasDisabledItems,
        };

        this.$store.commit('updateOrder', newOrder);

        await this.$store.dispatch('fees/getFees', newOrder);

        const feeError = this.$store.getters['fees/error'];

        if (feeError) {
          this.nextStepActive = false;
          throw new Error(feeError);
        }

        const hasReturn = Object.values(this.selectedItems.items).some(
          (value) => value.returnType === 'credit'
        );

        Object.keys(this.selectedItems.items).map((key) => {
          this.$store.dispatch('workflows/evaluate', {
            lineItem: newOrder.line_items[key],
            order: newOrder,
            trigger: 'item-selected',
          });
        });

        // Decide on showing the credit page
        // If cart is being used - if total is 0 or less, skip credit page
        if (this.isItemInCart && this.$store.state.totals.receipt.total <= 0) {
          skipCredit = true;
        }

        // skip credit page if storefront is disabled
        // and refund amount is 0 or less
        if (this.order.enabled.storefront !== 'yes' && this.refundAmount <= 0) {
          skipCredit = true;
        }

        // customer chose "shop now" for at least one itemx
        const hasStoreFrontReturn = Object.values(this.selectedItems.items).some(
          (value) => value.storefront
        );

        if (
          !skipCredit &&
          (this.totals.computed.remainingCredit > 0 ||
            (this.totals.credit.available > 0 && hasReturn) ||
            hasStoreFrontReturn ||
            shopNowForAll)
        ) {
          this.$router.push({ name: 'credit' });
          return;
        }

        // Default to pushing straight to the review page
        this.$router.push({ name: 'review' });
      } catch (error) {
        this.$store.commit('setError', {
          copy: error,
        });
      } finally {
        this.submitting = false;
      }
    },
    toggleCart() {
      this.showCart = !this.showCart;
    },
    async toShop() {
      if (this.onStoreActive) {
        await this.$store.dispatch('totals/get');
        await navigateToOnstore('exchange');
        return;
      }
      this.$router.push({ name: 'shop' });
    },
    async toReview() {
      this.loading = true;
      await this.$store.dispatch('totals/get');
      this.loading = false;
      this.$router.push({ name: 'review' });
    },
    async updateProducts(index) {
      this.loading = true;
      const cart = this.order.cart ?? [];
      const updatedCart = cart
        .filter((item, cartIndex) => cartIndex !== index);

      try {
        // If they have a cart token they're coming from on-store and we need
        // to update that cart. In app carts are purely client side and do not need this
        if (this.$store.state.cartToken) {
          await Cart.update(updatedCart.map(item => `${item.variant_id}`));
        }

        this.$store.commit('updateOrder', {
          ...this.order,
          cart: updatedCart
        });
        const variant = cart.find((_, cartIndex) => cartIndex === index);
        this.$store.commit('return/removeNewItem', { variantId: variant.variant_id ?? variant.id });

        const { errors } = await this.$store.dispatch('totals/get');
        if (errors) {
          throw new Error(errors);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$error(this.$content.moduleReturnItems.removalError);
      }
    },
    toggleFlowBFinalStep() {
      this.flowBFinalStep = true;
    }
  },
  render: (h) => h(this),
};
</script>

<style lang="scss" scoped>
.app-wrap {
  &__backdrop {
    z-index: -1;
    opacity: 0.3;
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }

  &__backdrop-gradient {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0%) 0%, #fff 27.42%, #fff 72.54%, rgba(255, 255, 255, 0%) 100%);
  }
}

.app-banner {
  h1 {
    @include font-heading1;
  }

  p {
    @include font-body;
  }
}

.multicurrency-section {
  margin-top: var(--spacing-500);
}

.exchange-banner {
  &__add-more-later,
  &__single-item {
    text-align: center !important;
  }
}

.exchange-ineligible {
  &__icon {
    width: 1rem;
    height: 1rem;
    margin-left: var(--spacing-200);
  }
}

</style>
