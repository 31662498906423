<template>
  <div v-if="isStoreCreditOnly">
    <span class="store-credit-only-badge">{{ $content.pageOrder.storeCreditOnly }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  product: {
    type: Object,
    required: true
  }
});

const isStoreCreditOnly = computed(() => {
  if (props.product.outcome === 'store_credit_only') return true;
  const orderLineItems = store.getters.order?.line_items;
  const product = orderLineItems ? orderLineItems[props.product.id] : null;

  // To show the badge - we need a product that can be returned, has not been returned, and only has the allowed outcome of store credit

  return !!product
    && product?.allowed?.gift
    && product?.allowed?.return
    && !product?.allowed?.returned
    && !product?.allowed?.refund
    && !product?.allowed?.exchange
    && !product?.allowed?.shopNow
    && !product?.allowed?.replace;
});
</script>

<style lang="scss" scoped>
  .store-credit-only-badge {
    line-height: 130%;
    display: inline-flex;
    padding: 0.125rem 0.5rem;
    border-radius: 4px;
    font-size: 0.65rem;
    font-weight: 400;
    color: $gray-darker;
    background-color: #edeef1;
  }
</style>
