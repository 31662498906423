export const featureFlags = {
  ENABLE_CUSTOMER_PORTAL_INFORMATION_BANNER: 'enable-customer-portal-information-banner',
  EXCHANGE_ORDER_CHECKOUT: 'exchange-order-checkout',
  INSTANT_REFUNDS: 'instant-refunds',
  RETURN_SUMMARY_NEGATIVE_POSITIVE_IDENTIFIERS: 'return-summary-negative-positive-identifiers',
  SIGNIFYD: 'signifyd',
  SMART_RECOMMENDATIONS: 'smart-recommendations',
  SHOP_CASH: 'shop-cash',
  TAX_INCLUSIVE_PACKING_SLIP_PRICES: 'tax-inclusive-packing-slip-prices',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  RETURNS_PORTAL_HIDE_FEES_ON_CREDIT_TYPE_SELECTION_CARDS: 'returns-portal-hide-fees-on-credit-type-selection-cards',
  OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED: 'oceania-address-improvements-enabled',
  MULTI_LABELS_ALLOWED: 'multi-labels-allowed',
  PER_PRODUCT_HANDLING_FEES: 'per-product-handling-fees',
  OFFSET_INTERNATIONAL_RETURN_POLICIES: 'offset-international-return-policies',
  SHOP_NOW_PRICES_CONVERSION: 'shop-now-prices-conversion',
  SHIPPING_PROTECTION: 'shipping-protection',
  EMBEDDED_PORTAL: 'embedded-portal',
  CUSTOMER_SATISFACTION_SURVEY_ENABLED: 'customer-satisfaction-survey-enabled',
};
